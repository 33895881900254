import * as React from "react";
import { FunctionComponent } from "react";
import SubmissionSuccessPage from "../templates/SubmissionSuccess/SubmissionSuccess";

const RequestAProposalSuccess: FunctionComponent = (): JSX.Element => (
  <SubmissionSuccessPage
    heading="Request a Proposal Submitted"
    content="Thank you. Your request has been received. A representative from Hawaiiana Management Company will be in touch with your shortly."
  />
);

export default RequestAProposalSuccess;
